<template>
  <div class="page">
    <h2>分类批发</h2>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-cascader ref="cascader" style="margin-bottom: 10px" :options="unuseCateList" placeholder="请选择分类" :show-all-levels="false" :props="{value:'id',label:'name',children:'subclass',emitPath: false,checkStrictly: true,expandTrigger: 'hover'}" filterable clearable @change="setCate"></el-cascader>
        <el-tabs v-model="activeName" tab-position="left" closable @tab-remove="removeTab">
          <el-tab-pane v-for="(item,index) in cateList" :label="item.cate_text" :name="index"></el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="14" :offset="2">
        <el-table v-if="cateList.length>0" :data="cateList[activeName].ladder_price">
          <el-table-column label="数量">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="价格">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price">
                <span slot="suffix">%</span>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-link :underline="false" type="danger" @click="priceList.splice(scope.$index,1)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-button @click="priceListAdd" style="width: 100%;margin-top: 5px">+</el-button>
      </el-col>
    </el-row>
    <div style="text-align: right;margin-top: 10px">
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script>
import {apiDelSetCate, apiGetSetCate, apiGetUnuseCate, apiOperationWholesale} from "@/request/api";

export default {
  name: "cateWholesale",
  data(){
    return{
      activeName:0,
      cateList:[],
      priceList:[],
      unuseCateList:[]
    }
  },
  watch:{
    cateList(){
      this.$refs['cascader'].dropDownVisible = false
    }
  },
  created() {
    this.getCateList()
    this.getUnuseCateList()
  },
  methods:{
    priceListAdd(){
      this.cateList[this.activeName].ladder_price.push({number:null,price:null})
    },
    getUnuseCateList(){
      apiGetUnuseCate().then(res => {
        if(res.code == 200){
          this.unuseCateList = res.data
          this.unuseCateList.forEach(cate => {
            cate.ladder_price.forEach(item => {
              item.price = item.price * 100
            })
          })
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getCateList(){
      apiGetSetCate().then(res => {
        if(res.code == 200){
          this.cateList = res.data
          if (this.cateList.length > 0){
              this.cateList.forEach(cate => {
                  cate.ladder_price.forEach(item => {
                      item.price = item.price * 100
                  })
              })
          }
        }else{
          this.$message.error(res.message)
        }
      })
    },
    submit(){
      var cate = JSON.parse(JSON.stringify(this.cateList[this.activeName]))
      cate.ladder_price.forEach(item => {
        item.price = item.price / 100
      })
      apiOperationWholesale({data:[cate]}).then(res => {
        if(res.code == 200){
          this.$message.success(res.message)
        }else{
          this.$message.error(res.message)
        }
      })
    },
    removeTab(item) {

      this.$confirm('是否确认删除该分类', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(!this.cateList[item].id){
          this.cateList.splice(item,1)
          return
        }
        apiDelSetCate({id:this.cateList[item].id}).then(res => {
          if(res.code == 200){
            this.$message.success(res.message)
            this.cateList.splice(item,1)
          }else {
            this.$message.error(res.message)
          }
        })
      })
    },
    setCate(){
      let nodesInfo = this.$refs['cascader'].getCheckedNodes()
      var v =  this.cateList.some(item => {
        return item.cate_id == nodesInfo[0].data.id
      })
      console.log(v)
      if(v) {
        return
      }
      this.cateList.push({
        cate_id:nodesInfo[0].data.id,
        kind:1,
        cate_text:nodesInfo[0].data.name,
          ladder_price:[]
      })
      console.log(this.$refs['cascader'])

      this.$refs['cascader'].checkedValue = null
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
</style>
